import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const BlogPost = (props) => {

	const data = props.data
	// console.log(props, data)
	const image = getImage(data.mdx.frontmatter.hero_image)

	return (
		<Layout>
			<p>Posted: {data.mdx.frontmatter.date}</p>
			<GatsbyImage
				image={image}
				alt={data.mdx.frontmatter.hero_image_alt}
			/>
			<p>
				Photo Credit:{" "}
				<a href={data.mdx.frontmatter.hero_image_credit_link}>
					{data.mdx.frontmatter.hero_image_credit_text}
				</a>
			</p>
			<MDXRenderer>
				{data.mdx.body}
			</MDXRenderer>
		</Layout>
	)
}

export const query = graphql`
query ($slug: String) {
  mdx(slug: {eq: $slug}) {
    id
    slug
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
  }
}
`

export default BlogPost